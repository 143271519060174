import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import script_YrMPz5ICtR from "/vercel/path0/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import motion_fmZWau4t0U from "/vercel/path0/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import titles_m9SjywKgWm from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import modals_bidRKewKK5 from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import preview_client_yA6XwA2wVr from "/vercel/path0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import i18n_yfWm7jX06p from "/vercel/path0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import presets_1aypKNZ222 from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import scrollbars_client_7kPaTs6T4H from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/scrollbars.client.ts";
import variables_kQtglGecod from "/vercel/path0/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import colors_SVBUrxEU7r from "/vercel/path0/plugins/colors.ts";
import detectSafari_uCFNnGuRAa from "/vercel/path0/plugins/detectSafari.ts";
import gsap_qE0fizvEy0 from "/vercel/path0/plugins/gsap.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import ui_rjNXKDZLQ9 from "/vercel/path0/plugins/ui.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  script_YrMPz5ICtR,
  motion_fmZWau4t0U,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  preview_client_yA6XwA2wVr,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  presets_1aypKNZ222,
  scrollbars_client_7kPaTs6T4H,
  variables_kQtglGecod,
  colors_SVBUrxEU7r,
  detectSafari_uCFNnGuRAa,
  gsap_qE0fizvEy0,
  posthog_client_fUucxKWhZE,
  ui_rjNXKDZLQ9
]